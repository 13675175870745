import React, { useState, useEffect, createContext, useContext } from "react";
import { io } from "socket.io-client";
import { CurrentSlideContext } from "./CurrentSlideContext";
import presentationData from "../data/presentationData.json";


export const MaterialContext = createContext([]);

const socket = io.connect("wss://matlib.net", {
  path: "/node/socket.io",
  transports: ["websocket", "polling"],
});

// const socket = io.connect("http://localhost:3001", {
//   path: "/socket.io",
// });


const room = "jordan_prez";



const MaterialContextProvider = (props) => {
  const localStore = JSON.parse(localStorage.getItem("selected"));

  const [materialData, setMaterialData] = useState([]);
  const [materialUsed, setMaterialUsed] = useState([]);
  const [selected, setSelected] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [pageItems, setPageItems] = useState([]);
  const [slug, setSlug] = useState('')
  const [users, setUsers ] = useState([])

  const { currentSlide } = useContext(CurrentSlideContext);


  // socket.on("connect_error", () => {
  //   console.log('error')
  //   socket.io.opts.transports = ["polling", "websocket"];
  // });

  const joinRoom = () => {
    socket.emit("join_room", room);
  };

  useEffect(() => {
    joinRoom();
    if(localStore && localStore.length >= 1){
      setSelected(localStore)
    }
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (window.screen.width >= 1080) {
      socket.on("receive_likes", (data) => {
        setSelected(item => [...item, data.like]);
      });
    }
    socket.on("receive_slug", (data) => {
      setSlug(data.slug)
    });
    socket.on("receive_join", (data) => {
      setUsers(people => [...people, data])
    });
    socket.on("remove_user", (data) => {
      console.log('user left', data)

    });
    // eslint-disable-next-line
  }, [socket]);


  useEffect(() => {
    localStorage.setItem("selected", JSON.stringify(selected));
    // eslint-disable-next-line
  }, [selected]);

  const value = {
    materialData,
    materialUsed,
    selected,
    localStore,
    users,
    slug,
    socket,
    pageItems,
    changeSelected: (value) => {
      if(selected.includes(value)){
        const removeItem = selected.filter((items) => items !== value)
        setSelected(removeItem)
        localStorage.setItem("selected", JSON.stringify(removeItem));
      }else{
        setSelected([...selected, value])
        localStorage.setItem("selected", JSON.stringify([...selected, value]));
      }
    },
    sendLike: (data) => {
      if (data) {
        const likeData = {
          room: room,
          like: data,
        };
        socket.emit("send_likes", likeData);
      }
    },
    changeSlug: (data) => {
        const slugData = {
          room: room,
          slug: data,
        };
        socket.emit("send_slug", slugData);
    },
    changePageItems: (items) => {
      setPageItems(items);
    },
    clearSelected: () => {
      setSelected([]);
      localStorage.setItem("selected", JSON.stringify([]));
    },
    changeMaterialUsed: (value) => {
      setMaterialUsed([...materialUsed, ...value]);
    },
    itemData,
    changeItemData: (value) => {
      setItemData(value);
    },
  };

  const materialIds =
    "194,128,196,4019,1032,3535,2747,4176,4149,4169,795,1014,2942,1126,912,3463,3541,4066,1010,1005,4029,3571,3999,1057,447,1018,2938,1022,2947,2348,1262,1883,1946,1915,1188,1716,809,4253,4183,3822,4272,3977,4357,4267";

  function getData() {
    fetch(`https://matlib.co/wp-json/ml/v1/byId?ids=${materialIds}`)
      .then((response) => response.json())
      .then((data) => setMaterialData(data));
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const view = presentationData[0].pages[currentSlide].view
    const messageData = {
      room: room,
      message: `Page ${currentSlide}`,
      count: currentSlide,
      view: view,
      items: pageItems ? pageItems : [],
      time:
        new Date(Date.now()).getHours() +
        ":" +
        new Date(Date.now()).getMinutes(),
    };

    socket.emit("send_message", messageData);
    // eslint-disable-next-line
  }, [currentSlide, pageItems]);

  return (
    <MaterialContext.Provider value={value}>
      {props.children}
    </MaterialContext.Provider>
  );
};

export default MaterialContextProvider;
