import React from "react";
import { motion } from "framer-motion";
import UnkindHide from "../images/unkindHide2.png";
import ThreeD from '../images/3DSkin.png';
import KindLogo from '../images/kindLeatherLogo21.png'

function SkinSVG({stepper}) {

    const message = [
        {
            title: `<img src="${KindLogo}" alt="kind leather" style="filter:invert(1) drop-shadow(0 0 1px rgba(0,0,0,0.8))" width="400"/>`,
            message: ``
        },
        {
            title: '85% Cuttablity',
            message: 'Standard A Grade cutting'
        },
        {
            title: '90% Cuttablity',
            message: 'Current Kind Leather Standard'
        },
        {
            title: '95% Cuttability',
            message: 'Kind Leather new goal for 2021'
        }
    ]

    const delay = 1;

  return (
    <>
     {stepper === 0 &&  <img className="unkind-hide-img" src={UnkindHide} alt="unkind" />}
      <motion.div 
       initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 2,
          delay: delay
        },
      }}
      
      
      className="skin-text">
       <h3
          dangerouslySetInnerHTML={{
            __html: message[stepper].title,
          }}
        />
      </motion.div>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 1920 1080"
        width="700"
        height="700"
        style={{ zIndex: 1 }}
      >
        <g id="Layer_1">
          <image width="1920" height="1080" href={ThreeD}></image>
        </g>
        <defs>
		<pattern id="stripes" patternUnits="userSpaceOnUse" width="28" height="28" patternTransform="rotate(45)">
        <line x1="0" y="0" x2="0" y2="28" stroke="#F4FB56" strokeWidth="30" />
		</pattern>
	    </defs>
        {stepper === 3 && <g id="percent_93">
        <path
            //fill={fillColor}
            fill="url(#stripes)"
            d="M164.9,48.7c-0.5-0.1-1-0.1-1.5-0.1h0c0,0,0,0,0,0c-4.9-1.4-9.9-2.3-15.1-2c0,0,0,0,0,0c-0.7,0-1.3,0-2,0
    c0,0,0,0,0,0c-7,0-14,0.3-21-1c-5.1,0.5-10.1,1.4-15,2.9c0,0,0,0,0,0c-1.5-0.1-2.7,0.3-4,1c-5.9-0.8-9.7,3.1-14,6c0,0,0,0,0,0
    c0,0,0,0,0,0c-3.7,6.5-1.7,14.1-4.1,20.9c0,0,0,0,0,0c-1.9,6.2-2.1,12.6-2,19c0,0,0,0,0,0c0,6.6,0,13.3,0,19.9c0,0,0,0,0,0
    c-1.6,6-3.2,11.9-4.7,17.9c-0.4,1.4-0.7,3,0.7,4.1c0,0,0,0,0,0c-2.9,6.2-3.2,12.9-3,19.6c0.2,5.4,0,10.9,0,16.3
    c-3.4,14.1-4.3,28.6-4.8,43c-0.6,19.2-0.2,38.5-0.1,57.7c0,1.8-0.6,3.7,0.9,5.2c0,0,0,0,0,0c-0.9,8.9-1.1,17.9-1,26.9c0,0,0,0,0,0
    c-0.3,0.4-0.7,0.8-0.8,1.2c-1.1,13.2-2.1,26.5-3.2,39.8c0,0,0,0,0,0c-1.4,7.8-2.6,15.6-4.3,23.3c-2.6,11.4-4.7,22.9-5.7,34.6
    c-1.2,3.6-2,7.2-2,11c0,0,0,0,0,0c-1.7,6.3-3.5,12.5-5.1,18.8c-1.3,5.4-3.5,10.6-3.9,16.2c-2.4,7.8-4.2,15.7-4,23.9
    c-0.2,0.4-0.3,0.9-0.4,1.3c-1.8,5.8-2.1,11.9-3.6,17.7c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,1.6-0.5,3.3-0.9,4.9
    C37,514.5,36,530.2,38.4,546c1,6.4,1.2,13,4.7,18.8c1.5,2.4,1.4,5.3,1.3,8.1c0,0,0,0,0,0c0,0,0,0,0,0c0,4.2,0,8.3,0,12.5
    c-0.2,10.9,1.5,21.8-0.8,32.6c-0.3,1.5,0.6,2.9,1.8,3.9c2.7,4,5.6,7.7,9.9,10c0,0,0,0,0,0c3.3,4.3,6.6,8.7,11,12c0,0,0,0,0,0
    c0.9,1.6,1.6,3.3,3.9,3.1c0,0,0,0,0,0c1.3,4.7,4.6,7.9,8.2,10.8c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.3,0.7,0.6,1,0.9c0,0,0,0,0,0
    c0,0,0,0,0,0c2,2.7,4.6,4.9,6,8.1c0,0,0,0,0,0c0,0,0,0,0,0c1.2,2.1,1.7,4.6,3.9,6c0,0,0,0,0,0c3.1,6,7.9,10.6,13,15c0,0,0,0,0,0
    c1.4,2,3,3.7,5,5c0.9,1.6,2,2.9,4.1,2.9l0,0c1.3,0,2.6,0,4,0v0c0.7,0,1.3,0,2,0.1c0.3-0.1,0.5-0.1,0.7-0.2
    c-0.1,0.1-0.4,0.2-0.7,0.2c3,0.3,6,0.7,9,1c0,0,0,0,0,0c0.9,1,2,0.9,3.1,0.7c0,0,0,0,0,0c0,0,0,0,0,0c3.6,1.1,7.3,2.2,10.9,3.3
    c1.6,1,3.3,2,4.9,3c0,0,0,0,0,0c0.6,0.3,1.3,0.6,1.9,0.9c0,0,0,0,0,0c2.5,2.6,4.9,5.3,8.3,6.9c0,0,0,0,0,0c0,0,0,0,0,0
    c1.4,1.5,3,2.6,4.9,3.3c0,0,0,0,0,0c0.3,0.3,0.6,0.5,0.9,0.8c0,0,0,0,0,0c0.9,1.5,2.3,2,3.7,2.4V48.7z"
          />
        </g>}
        {stepper === 2 && <g id="percent90">
          <path
             fill="url(#stripes)"
            d="M219.7,72c-4.4-3-8.6-6.3-13.6-8.3c0,0,0,0,0,0c0,0,0,0,0,0c-9.7-8.1-21.9-9.8-33.5-13.2
    c-1.9-1.3-4.1-0.8-6.2-1c0,0,0,0,0,0c-0.8-0.9-1.9-0.8-3-0.9h0c0,0,0,0,0,0c-4.9-1.4-9.9-2.3-15.1-2c0,0,0,0,0,0c-0.7,0-1.3,0-2,0
    c0,0,0,0,0,0c-7,0-14,0.3-21-1c-5.1,0.5-10.1,1.4-15,2.9c0,0,0,0,0,0c-1.5-0.1-2.7,0.3-4,1c-5.9-0.8-9.7,3.1-14,6c0,0,0,0,0,0
    c0,0,0,0,0,0c-3.7,6.5-1.7,14.1-4.1,20.9c0,0,0,0,0,0c-1.9,6.2-2.1,12.6-2,19c0,0,0,0,0,0c0,6.6,0,13.3,0,19.9c0,0,0,0,0,0
    c-1.6,6-3.2,11.9-4.7,17.9c-0.4,1.4-0.7,3,0.7,4.1c0,0,0,0,0,0c-2.9,6.2-3.2,12.9-3,19.6c0.2,5.4,0,10.9,0,16.3
    c-3.4,14.1-4.3,28.6-4.8,43c-0.6,19.2-0.2,38.5-0.1,57.7c0,1.8-0.6,3.7,0.9,5.2c0,0,0,0,0,0c-0.9,8.9-1.1,17.9-1,26.9c0,0,0,0,0,0
    c-0.3,0.4-0.7,0.8-0.8,1.2c-1.1,13.2-2.1,26.5-3.2,39.8c0,0,0,0,0,0c-1.4,7.8-2.6,15.6-4.3,23.3c-2.6,11.4-4.7,22.9-5.7,34.6
    c-1.2,3.6-2,7.2-2,11c0,0,0,0,0,0c-1.7,6.3-3.5,12.5-5.1,18.8c-1.3,5.4-3.5,10.6-3.9,16.2c-2.4,7.8-4.2,15.7-4,23.9
    c-0.2,0.4-0.3,0.9-0.4,1.3c-1.8,5.8-2.1,11.9-3.6,17.7c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,1.6-0.5,3.3-0.9,4.9
    C37,514.5,36,530.2,38.4,546c1,6.4,1.2,13,4.7,18.8c1.5,2.4,1.4,5.3,1.3,8.1c0,0,0,0,0,0c0,0,0,0,0,0c0,4.2,0,8.3,0,12.5
    c-0.2,10.9,1.5,21.8-0.8,32.6c-0.3,1.5,0.6,2.9,1.8,3.9c2.7,4,5.6,7.7,9.9,10c0,0,0,0,0,0c3.3,4.3,6.6,8.7,11,12c0,0,0,0,0,0
    c0.9,1.6,1.6,3.3,3.9,3.1c0,0,0,0,0,0c1.3,4.7,4.6,7.9,8.2,10.8c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.3,0.7,0.6,1,0.9c0,0,0,0,0,0
    c0,0,0,0,0,0c2,2.7,4.6,4.9,6,8.1c0,0,0,0,0,0c0,0,0,0,0,0c1.2,2.1,1.7,4.6,3.9,6c0,0,0,0,0,0c3.1,6,7.9,10.6,13,15c0,0,0,0,0,0
    c1.4,2,3,3.7,5,5c0.9,1.6,2,2.9,4.1,2.9l0,0c1.3,0,2.6,0,4,0v0c0.7,0,1.3,0,2,0.1c0.3-0.1,0.5-0.1,0.7-0.2
    c-0.1,0.1-0.4,0.2-0.7,0.2c3,0.3,6,0.7,9,1c0,0,0,0,0,0c0.9,1,2,0.9,3.1,0.7c0,0,0,0,0,0c0,0,0,0,0,0c3.6,1.1,7.3,2.2,10.9,3.3
    c1.6,1,3.3,2,4.9,3c0,0,0,0,0,0c0.6,0.3,1.3,0.6,1.9,0.9c0,0,0,0,0,0c2.5,2.6,4.9,5.3,8.3,6.9c0,0,0,0,0,0c0,0,0,0,0,0
    c1.4,1.5,3,2.6,4.9,3.3c0,0,0,0,0,0c0.3,0.3,0.6,0.5,0.9,0.8c0,0,0,0,0,0c1.4,2.3,3.9,2.4,6.1,3c1.7,1.3,3.6,2,5.7,2.5
    c4.8,1.3,10.1,1.2,14.4,4.4c0,0,0,0,0,0c1.3,1,2.7,2,4,2.9c0,0,0,0,0,0c2.3,1.7,4.6,3.4,6.9,5v0c2.2,3.6,6,5.4,9.1,8c0,0,0,0,0,0
    c0,0,0,0,0,0c1.3,1,2.6,2,3.9,3c0.9,1.6,2,3,4.1,3l0,0c1.3,1,2.7,2,4,3c0.1,0.1,0.3,0.2,0.4,0.3V72z"
          />
        </g>}
       {stepper === 1 && <g id="percent85">
       <motion.path     
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={8}
          initial={{
            opacity: 0,
            pathLength: 0,
            fill:'none',

          }}
          animate={{
            opacity: 1,
            pathLength: 1,
            transitionEnd:{
                fill:"url(#stripes)",
                stroke:"none",
            },
            transition: {
              duration: 2,
              delay: 1
            },
          }}
            d="M311,99.5c-2.6,0.1-5.2,0.4-7.7-1c0,0,0,0,0,0c-0.4-0.3-0.8-0.5-1.2-0.8c0,0,0,0,0,0c-3.5-1.6-6.9-3.6-11-3
    c0,0,0,0,0,0c-6.3-2.3-12-6.1-18.8-7.2c0,0,0,0,0,0c-3-2.1-6.9-0.1-9.9-2.1c0,0,0,0,0,0c-2.9-1.2-5.9-1.6-9-2c0,0,0,0,0,0
    c-1.8-1.5-4-0.7-5.9-1c-0.1,0-0.1,0-0.2,0c-2.7-0.6-5.3-1.2-8-1.9l0,0c-0.3-0.1-0.6-0.2-0.9-0.2c0,0,0,0,0,0
    c-0.4-0.2-0.8-0.4-1.2-0.6c0,0,0,0,0,0c0,0,0,0,0,0c-1.7-0.1-3-1.9-4.8-1.2c-4.2-1.5-7.5-4.9-11.9-6.1c-4.6-3.2-9.1-6.7-14.4-8.9
    c0,0,0,0,0,0c0,0,0,0,0,0c-9.7-8.1-21.9-9.8-33.5-13.2c-1.9-1.3-4.1-0.8-6.2-1c0,0,0,0,0,0c-0.8-0.9-1.9-0.8-3-0.9h0c0,0,0,0,0,0
    c-4.9-1.4-9.9-2.3-15.1-2c0,0,0,0,0,0c-0.7,0-1.3,0-2,0c0,0,0,0,0,0c-7,0-14,0.3-21-1c-5.1,0.5-10.1,1.4-15,2.9c0,0,0,0,0,0
    c-1.5-0.1-2.7,0.3-4,1c-5.9-0.8-9.7,3.1-14,6c0,0,0,0,0,0c0,0,0,0,0,0c-3.7,6.5-1.7,14.1-4.1,20.9c0,0,0,0,0,0
    c-1.9,6.2-2.1,12.6-2,19c0,0,0,0,0,0c0,6.6,0,13.3,0,19.9c0,0,0,0,0,0c-1.6,6-3.2,11.9-4.7,17.9c-0.4,1.4-0.7,3,0.7,4.1
    c0,0,0,0,0,0c-2.9,6.2-3.2,12.9-3,19.6c0.2,5.4,0,10.9,0,16.3c-3.4,14.1-4.3,28.6-4.8,43c-0.6,19.2-0.2,38.5-0.1,57.7
    c0,1.8-0.6,3.7,0.9,5.2c0,0,0,0,0,0c-0.9,8.9-1.1,17.9-1,26.9c0,0,0,0,0,0c-0.3,0.4-0.7,0.8-0.8,1.2c-1.1,13.2-2.1,26.5-3.2,39.8
    c0,0,0,0,0,0c-1.4,7.8-2.6,15.6-4.3,23.3c-2.6,11.4-4.7,22.9-5.7,34.6c-1.2,3.6-2,7.2-2,11c0,0,0,0,0,0c-1.7,6.3-3.5,12.5-5.1,18.8
    c-1.3,5.4-3.5,10.6-3.9,16.2c-2.4,7.8-4.2,15.7-4,23.9c-0.2,0.4-0.3,0.9-0.4,1.3c-1.8,5.8-2.1,11.9-3.6,17.7c0,0,0,0,0,0
    c0,0,0,0,0,0c-0.3,1.6-0.5,3.3-0.9,4.9C37,514.5,36,530.2,38.4,546c1,6.4,1.2,13,4.7,18.8c1.5,2.4,1.4,5.3,1.3,8.1c0,0,0,0,0,0
    c0,0,0,0,0,0c0,4.2,0,8.3,0,12.5c-0.2,10.9,1.5,21.8-0.8,32.6c-0.3,1.5,0.6,2.9,1.8,3.9c2.7,4,5.6,7.7,9.9,10c0,0,0,0,0,0
    c3.3,4.3,6.6,8.7,11,12c0,0,0,0,0,0c0.9,1.6,1.6,3.3,3.9,3.1c0,0,0,0,0,0c1.3,4.7,4.6,7.9,8.2,10.8c0,0,0,0,0,0c0,0,0,0,0,0
    c0.3,0.3,0.7,0.6,1,0.9c0,0,0,0,0,0c0,0,0,0,0,0c2,2.7,4.6,4.9,6,8.1c0,0,0,0,0,0c0,0,0,0,0,0c1.2,2.1,1.7,4.6,3.9,6c0,0,0,0,0,0
    c3.1,6,7.9,10.6,13,15c0,0,0,0,0,0c1.4,2,3,3.7,5,5c0.9,1.6,2,2.9,4.1,2.9l0,0c1.3,0,2.6,0,4,0v0c0.7,0,1.3,0,2,0.1
    c0.3-0.1,0.5-0.1,0.7-0.2c-0.1,0.1-0.4,0.2-0.7,0.2c3,0.3,6,0.7,9,1c0,0,0,0,0,0c0.9,1,2,0.9,3.1,0.7c0,0,0,0,0,0c0,0,0,0,0,0
    c3.6,1.1,7.3,2.2,10.9,3.3c1.6,1,3.3,2,4.9,3c0,0,0,0,0,0c0.6,0.3,1.3,0.6,1.9,0.9c0,0,0,0,0,0c2.5,2.6,4.9,5.3,8.3,6.9
    c0,0,0,0,0,0c0,0,0,0,0,0c1.4,1.5,3,2.6,4.9,3.3c0,0,0,0,0,0c0.3,0.3,0.6,0.5,0.9,0.8c0,0,0,0,0,0c1.4,2.3,3.9,2.4,6.1,3
    c1.7,1.3,3.6,2,5.7,2.5c4.8,1.3,10.1,1.2,14.4,4.4c0,0,0,0,0,0c1.3,1,2.7,2,4,2.9c0,0,0,0,0,0c2.3,1.7,4.6,3.4,6.9,5v0
    c2.2,3.6,6,5.4,9.1,8c0,0,0,0,0,0c0,0,0,0,0,0c1.3,1,2.6,2,3.9,3c0.9,1.6,2,3,4.1,3l0,0c1.3,1,2.7,2,4,3c3.7,2.3,7.3,4.7,11,7
    c1.6,2,3.5,3.4,6,3.9c0,0,0,0,0,0c0,0,0,0,0,0c2.2,0.9,4.3,2,6.8,2.1c0,0,0,0,0,0c3.6,1.6,7.3,3.3,10.9,5c0,0,0,0,0,0
    c0.8,1.2,2,1.8,3.2,2.2c0,0,0,0,0,0c0.3,0.3,0.7,0.5,1,0.8c0,0,0,0,0,0c2.4,2.6,3.8,6,6.8,8.1c0,0,0,0,0,0c1.3,1.7,2.8,3.1,5.2,3
    c0,0,0-0.3,0-0.3c0.1,0,0.1,0,0.2,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.3-0.1,0.6,0,0.8c2.7,3,6.2,4.8,9.9,6.1c0,0,0,0,0,0l0,0
    c0,0,0,0,0,0c0,0,0,0,0,0l0,0c1,2.1,3.1,2.5,5,3.1c0,0,0,0,0,0c1.1,0.6,2.1,1.1,3.2,1.7c0,0,0,0,0,0c1.2,0.7,2.4,1.3,3.8,1.2l0,0
    c0,0,0,0,0,0c1.5,1.2,3.3,1.6,5.1,1.9c0.1-0.2,0.3-0.4,0.4-0.5c-0.1,0.2-0.3,0.4-0.4,0.5c3.8,1.7,7.4,3.7,11.8,3.2c0,0,0,0,0,0
    c0.6,0.4,1.3,0.7,1.9,1V99.5z"
          />
        </g>}
      </motion.svg>
      <motion.div 
       initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 2,
        },
      }}
      className="skin-message">
       <h3
          dangerouslySetInnerHTML={{
            __html: message[stepper].message,
          }}
        />
      </motion.div>
      
    </>
  );
}

export default SkinSVG;
