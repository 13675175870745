import React, { useContext, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { MaterialContext } from "../context/MaterialContext";
import { circleIcon } from "./Icons";
import "../styles/mobile.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import presentationData from "../data/presentationData.json";
//import MobileSwatch from "./MobileSwatch";

function Mobile() {
  const { materialData, selected, sendLike, socket, changeSelected } =
    useContext(MaterialContext);

  const params = useParams();
  const history = useHistory();

  const [material, setMaterial] = useState([]);
  const [images, setImages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [materialItem, setMaterialItem] = useState(params.slug);
  const [imageIndex, setImageIndex ] = useState(0)

  useEffect(() => {
    setMaterialItem(params.slug);
    setImageIndex(0)
    setLoaded(false)
  }, [params]);

  useEffect(() => {
    const materialSetter = materialData.find(
      (item) => item.slug === materialItem
    );
    if (materialSetter) {
      setMaterial(materialSetter);
      setImages(materialSetter.gallery_medium);
      setLoaded(false)
    }
    setTimeout(() => {
      if (materialSetter === undefined) {
        setError(true);
      }
    }, 4000);
  }, [materialData, materialItem]);

  useEffect(() => {
    socket.on("receive_slug", (data) => {
      setMaterialItem(data.slug);
    });
   
  }, [socket, history]);

  // const jumpTo = (index) => {
  //   //setPage([index, 0]);
  // };

  const handleSave = (data) => {
    sendLike(data);
    changeSelected(data);
  };
  const handleChangeImage = (index) => {
    setImageIndex(index)
    //setLoaded(false)
  }

  if (material && images.length > 0)
    return (
      <div className="mobile-container">
        <div className="mobile-image-container">
          <div className="large-image-container">
            {!loaded && <div className="large-image-loader mobile-loader">{circleIcon}</div>}
            <Carousel stopOnHover={true} interval={9999999} swipeScrollTolerance={2} selectedItem={imageIndex} onChange={(currentSlide) => handleChangeImage(currentSlide)} swipeable={true} autoPlay={false} infiniteLoop={true} showThumbs={false} showStatus={false} showArrows={false}>
   
              {images &&
                images.map((image, index) => (
                  <div key={index} className="slide-div" style={{filter: loaded ? "blur(0px)" : "blur(5px)"}}>
                    <img
                      onLoad={() => setLoaded(true)}
                      src={image}
                      alt={material.title +'-'+ index}
                      
                    />
                  </div>
                ))}
            </Carousel>
          </div>

          {/* {images.length !== 0 && images.map(image => <li key={image} style={{fontSize:'10px'}}>{image}</li>)} */}
        </div>
        <div className="mobile-info-container">
        <h6>{material.vendor}</h6>
          <h4>{material.title}</h4>
          <h5>{material.description}</h5>
          <hr />
          <h5>${material.price} / sq.ft.</h5>
         
          {material.type === "cowhide" && <h5>Kind Leather</h5>}
          <hr />
          <h6>{material.search && material.search.replace(/,/g, ", ")}</h6>

          {selected.includes(material.id) ? (
            <div className="save-selection-button-stored">
              <h5>{material.title} STORED</h5>
            </div>
          ) : (
            <button
              className="save-selection-button"
              onClick={() => handleSave(material.id)}
            >
              <h5>STORE THIS ITEM </h5>
            </button>
          )}
        </div>
      </div>
    );
  if (error) {
    return (
      <div className="mobile-container">
        <div className="mobile-error">
          <h2>Oh No!</h2>
          <h3> Something went horribly wrong with </h3>
          <h3>
            <span style={{ color: "red" }}>
              {materialItem && materialItem.replace(/,/g, ", ")}
            </span>
          </h3>
          <h3>Try again</h3>
        </div>
      </div>
    );
  }
  return (
    <div className="mobile-container">
      <div className="large-image-loader">
        <h4
          style={{
            textTransform: "capitalize",
            position: "absolute",
            top: "30%",
            textAlign: "center",
            width: "80%",
          }}
        >
          LOADING UP <br />
          <br />
          {materialItem && materialItem.replace(/-/g, " ").toUpperCase()}
        </h4>
        {circleIcon}
      </div>
    </div>
  );
}

export default Mobile;
