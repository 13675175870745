import React, { useContext, useState } from "react";
import { MaterialContext } from "../context/MaterialContext";
import Search from './Search'
import Modal from './Modal'


function MaterialsList() {

  const { materialData, materialUsed, changeItemData, selected, users } = useContext(MaterialContext);
  const [ menu, setMenu ] = useState('selectedArray')
  function renderTableData() {
    return materialData.map((data, index) => {
      const { id, title, slug, description, price } = data; //destructuring
      return (
        <tr key={id} onClick={() => handleItem(data)} >
          <td>{index+1}</td>
          <td><h5>{id}</h5></td>
          <td><h5>{title.toUpperCase()}</h5></td>
          <td><h5>{slug}</h5></td>
          <td><h5>{description}</h5></td>
          <td><h5>${price}</h5></td>
          <td><h5>{materialUsed.includes(id) ? 'used' : 'not used'}</h5></td>
        </tr>
      );
    });
  }
  const presentationTable = (
    <table style={{width:'100%'}}>
    <tbody>
      <tr style={{background:'white', color:'black', fontSize:'16px', textTransform:'uppercase'}}>
        <td style={{ width: "20px", padding:'0 5px' }}><h5>#</h5></td>
        <td style={{ width: "30px", padding:'0 5px' }}><h5>ID</h5></td>
        <td style={{ width: "160px", padding:'0 5px' }}><h5>Title</h5></td>
        <td style={{ width: "100px", padding:'0 5px' }}><h5>Slug</h5></td>
        <td style={{ width: "260px",  padding:'0 5px'}}><h5>Description</h5></td>
        <td style={{ width: "60px",  padding:'0 5px'}}><h5>Price</h5></td>
        <td style={{ width: "60px",  padding:'0 5px'}}><h5>Used</h5></td>
      </tr>
      {materialData && renderTableData()}
    </tbody>
  </table>
  )

  const handleItem = (data) => {
    changeItemData(data)
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  function getTitle(id){
    const getTitle = materialData.find(item => item.id === id)
    if(getTitle){
      return getTitle.title;
    }
    return 'searching';
  }
  const selectedArray = (
    <>
    {selected.length >= 1 ? selected.filter(onlyUnique).map(item => <h5 key={item}>{getTitle(item)} - {selected.filter(value => value === item).length}</h5>) : <h4>No selected materials</h4>}
    </>
  )
  const userArray = (
    <div>
      <h6>{users.length} Online at the moment</h6>
      {users.map(item => <li key={item}> {item}</li>)}
    </div>
  )

  const display = {
    'presentationTable': presentationTable,
    'selectedArray': selectedArray,
    'userArray': userArray
  };

  function displayContent(content) {
    return display[content];
  }
  
  var drink = displayContent(menu);

  return (
    <>
    <div className="info-container">
      <div className="info-body">
        <div className="info-body-columns">
          <div className="column-one">
            <button onClick={() => setMenu('presentationTable')}>Table</button>
            <button onClick={() => setMenu('selectedArray')}>Selected</button>
            <button onClick={() => setMenu('userArray')}>User</button>
          </div>
          <div className="column-two">
          {drink}
          </div>
          <div className="column-three">
          <Search/>
         
          </div>
        </div>
      </div>
      <div className="directions-footer">
        <p>Data compiled from Matlib</p>
      </div>
     
    </div>
    <Modal/>
    </>
  );
}

export default MaterialsList;
