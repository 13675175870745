import React, { useContext, useState, useEffect } from "react";
import presentationData from "../data/presentationData.json";
import { useCurrentSlide } from "../context/CurrentSlideContext";
import {
  Link,
  useLocation,
  useRouteMatch,
  useHistory,
  useParams,
} from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { questionIcon, arrayIcon } from "./Icons";
import { MaterialContext } from "../context/MaterialContext";
import { closeIcon } from "./Icons";
import MobileSwatch from "./MobileSwatch";

function Footer() {
  const params = useParams();
  const { materialData, selected, socket } = useContext(MaterialContext);
  const { currentSlide, setSlide } = useCurrentSlide();
  const [pop, setPop] = useState(false);
  const [socketMessage, setSocketMessage] = useState("");
  const [pageItems, setPageItems] = useState([]);
  const [snackbarShow, setSnackbarShow] = useState(true);
  const [nowViewing, setNowViewing] = useState(params);
  const [viewType, setViewType] = useState("");

  //const localStore = JSON.parse(localStorage.getItem("selected"));

  const location = useLocation();
  const match = useRouteMatch("/m/:slug");
  const history = useHistory();

  const pageIndex = currentSlide;

  const MobilePop = () => {
    return (
      <>
        <div className="mobile-pop">
          <div style={{ textAlign: "center" }}>
            <h5>Stored Items</h5>
          </div>
          <div className="mobile-pop-scroll-box">
            {selected.length >= 1 ? (
              selected.map((item) => (
                <MobileSwatch
                  key={item}
                  setPop={setPop}
                  slug={
                    materialData.find((element) => element.id === item).slug
                  }
                />
              ))
            ) : (
              <h4>No selected materials</h4>
            )}
          </div>
          <div className="mobile-pop-close" onClick={() => setPop(false)}>
            {closeIcon}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    socket.on("receive_message", (data) => {
      setPageItems(data.items);
      setSocketMessage(presentationData[0].pages[data.count]);
      setViewType(data.view);
    });
    socket.on("receive_slug", (data) => {
      history.push(`/m/${data.slug}`);
      setNowViewing(data.slug);
    });
    // eslint-disable-next-line
  }, [socket, pageItems]);

  const handleSnackBar = (slug) => {
    history.push(`/m/${slug}`);
    setSnackbarShow(false);
  };

  const handleLink = (pageNo) => {
    if (currentSlide !== pageNo) {
      setSlide(pageNo);
    }
  };
  const { pages } = presentationData[0];

  if (location.pathname === "/m/" || match) {
    const MobileSocketBar = () => {
      function materialItem(slug) {
        if (slug) {
          const materialInfo = materialData.find((item) => item.slug === slug);
          return (
            <AnimatePresence key={materialInfo.id}>
              <motion.img
                animate={{
                  scale: nowViewing === materialInfo.slug ? 1.2 : 1,
                  y: nowViewing === materialInfo.slug ? 10 : 0,
                }}
                transition={{ duration: 0.5 }}
                exit={{ opacity: 0 }}
                src={materialInfo.tiny_square[0]}
                style={{
                  filter:
                    nowViewing === materialInfo.slug
                      ? "drop-shadow(0px 0px 3px black)"
                      : "drop-shadow(none)",
                }}
                alt={materialInfo.title}
                onClick={() => handleSnackBar(materialInfo.slug)}
              />
            </AnimatePresence>
          );
        } else {
          return <h5>Image not available</h5>;
        }
      }

      if (viewType === "material") {
        return (
          <>
            {snackbarShow ? (
              <div className="mobile-socket-bar">
                <div
                  className="mobile-pop-close"
                  onClick={() => setSnackbarShow(false)}
                >
                  {closeIcon}
                </div>
                {pageItems.length !== 0 && <h5>{socketMessage.desc}</h5>}
                {pageItems.length !== 0 && (
                  <div className="mobile-socket-bar-image">
                    {pageItems.map((item) => materialItem(item))}
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="page-title">
                  <h3>{socketMessage.desc}</h3>
                </div>
                <div
                  className="mobile-pop-close snackbar"
                  onClick={() => setSnackbarShow(true)}
                >
                  {closeIcon}
                </div>
              </>
            )}
          </>
        );
      }
      return (
        <>
          {snackbarShow ? (
            <div className="mobile-socket-bar">
              <div
                className="mobile-pop-close"
                onClick={() => setSnackbarShow(false)}
              >
                {closeIcon}
              </div>
              {pageItems.length !== 0 && (
                <h4>Prez page: {socketMessage.desc}</h4>
              )}
            </div>
          ) : (
            <>
              <div className="page-title">
                <h3>{socketMessage.desc}</h3>
              </div>
              <div
                className="mobile-pop-close snackbar"
                onClick={() => setSnackbarShow(true)}
              >
                {closeIcon}
              </div>
            </>
          )}
        </>
      );
    };

    return (
      <>
        {pop && <MobilePop />}
        <div
          className="footer"
          style={{
            position: "absolute",
            zIndex: 1,
            left: 0,
            bottom: 0,
            right: 0,
            pointerEvents: "none",
            paddingLeft: "16px",
            paddingRight: "16px",
            display: "flex",
          }}
        >
          {selected.length >= 1 ? (
            <h6 className="footer-items" onClick={() => setPop(true)}>
              view {selected.length} Stored{" "}
              {selected.length > 1 ? "items" : "item"}
            </h6>
          ) : (
            <h6>No stored items yet</h6>
          )}
        </div>
        <MobileSocketBar />
      </>
    );
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <div
        className="footer"
        style={{
          position: "absolute",
          zIndex: 1,
          left: 0,
          bottom: 0,
          right: 0,
          pointerEvents: "none",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        {/* {steps.length !== 0 ? <div className="footer-radios-box">{(currentStep + 1) / steps.length * 100}%</div> : null} */}
        {location.pathname === "/" ? (
          <motion.div
            key="expanded"
            initial={{ opacity: 0, duration: 2 }}
            animate={{ opacity: 1, duration: 2 }}
            exit={{ opacity: 0, duration: 2, delay: 1, x: 200 }}
            className="footer-radios-box"
          >
            {pages.map((page, index) => (
              <div
                key={index}
                role="button"
                aria-hidden="true"
                className="footer-radios"
                data-page={page.type}
                onClick={() => handleLink(index)}
                style={{
                  background: index <= pageIndex ? "#F4FB5680" : "none",
                  cursor: index === pageIndex ? "default" : "pointer",
                }}
              >
                <div className="progress-letters">{page.type}</div>
              </div>
            ))}

            <div className="presentation-array">
              <Link to={{ pathname: "/materials" }}>{arrayIcon}</Link>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="collapsed"
            initial={{ opacity: 0, duration: 2 }}
            animate={{ opacity: 1, duration: 2 }}
            exit={{ opacity: 0, duration: 2, delay: 1 }}
            className="footer-radios-box"
          >
            <Link to={{ pathname: "/" }} className="footer-return-link">
              Back to Presentation
            </Link>
          </motion.div>
        )}
        <div className="footer-question">
          <Link to={{ pathname: "/directions" }}>{questionIcon}</Link>
        </div>
        <div className="footer-slide-title">{pages[currentSlide].desc}</div>
      </div>
    </AnimatePresence>
  );
}

export default Footer;
