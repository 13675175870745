import React, { useEffect, useState } from "react";
import Steps from "./Steps";
import Transition from "./Transition";
import Grow from "../animation/Grow";
//import Step from "./Step";
import { useCurrentSlide } from "../context/CurrentSlideContext";
import KindLeatherLogo from "../images/kindLeatherLogo21.png";
import SVGAnimation from "../animation/SVGAnimation";

import WholeSkin from "../images/wholeSkin.png";
import WholeStripe from "../images/wholeSkinStripe.png";
import Exploded from "../images/explodedStripe.png";
import Trimmed from "../images/trimmedSkin.png";
import SkinSVG from "../animation/SkinSVG";
import Carbon from "../animation/Carbon";

function InformationDisplay() {
  const { currentStep, clearSteps } = useCurrentSlide();
  const [myMessage, setMyMessage] = useState([]);

  useEffect(() => {
    clearSteps();
    // eslint-disable-next-line
  },[]);

  const message = [
    {
      title: "The Basics",
      body: "Kind Leather is all about reducing waste before it becomes waste...",
    },
    {
      title: "The Basics",
      body: "Kind Leather is all about reducing waste before it becomes waste... <ul><li>Defect prone areas removed</li></ul>",
    },
    {
      title: "The Basics",
      body: "Kind Leather is all about reducing waste before it becomes waste... <ul><li>Defect prone areas removed</li><li>Trimmed leather is converted to protein</li></ul>",
    },
    {
      title: "The Basics",
      body: "Kind Leather is all about reducing waste before it becomes waste... <ul><li>Defect prone areas removed</li><li>Trimmed leather is converted to protein</li></ul>What's left is the most cuttable area of the skin",
    },
    {
      title: "Reduce Waste",
      body: "Shoe factories receive trimmed skins with 90% or greater cutting. <ul><li>Transport only usable leather</li><li>Water, power and chemistry are only added to usable leather, not landfill</li></ul>",
    },
    {
      title: "Reduce Waste",
      body: "Our goal for 2021 is to get even more out of Kind Leather. ",
    },
    {
      title: "Reduce Waste",
      body: "Our goal for 2021 is to get even more out of Kind Leather. <br/> <br/> We have successfully achieved 90% cuttable area since launch of Kind Leather",
    },
    {
      title: "Reduce Waste",
      body: "Our goal for 2021 is to get even more out of Kind Leather. <br/> <br/> Working with shoe factories to get up to 95% usage from Kind Leather.<ul><li>Less Waste going to landfill</li><li>Greater Cutting Efficiency</li><li>Increased Cost Savings</li></ul>",
    },
    {
      title: "Tracing CO<sup>2</sup>",
      body: "Asiatan and our supply partners are working on a strategy to deliver lower carbon footprint leather to factories.<br/><br/> More to come in 2022!",
    },
  ];

  useEffect(() => {
    if (currentStep === 0) {
      setMyMessage(message);
    }
    // eslint-disable-next-line
  }, [currentStep]);

  return (
    <div className="slide-container">
      <div className="kind-leather-logo">
        <img src={KindLeatherLogo} alt="kind leather" width="200" />
      </div>
      <div className="info-slide-left">
        <h2
          style={{ color: "#313131" }}
          dangerouslySetInnerHTML={{
            __html: myMessage.length > 0 && myMessage[currentStep].title,
          }}
        />
        <h5
          style={{ color: "#FEFEFE" }}
          dangerouslySetInnerHTML={{
            __html: myMessage.length > 0 && myMessage[currentStep].body,
          }}
        />
      </div>
      <div className="info-slide-right">
        <Steps>
          <Transition duration={1}>
           
            {currentStep === 0 && <img src={WholeSkin} alt="whole" />}
          </Transition>
          <Transition duration={0}>
            <img
              style={{ position: "absolute", zIndex: 0 }}
              src={WholeStripe}
              alt="whole"
            />
            {currentStep === 1 && <SVGAnimation />}
          </Transition>
          <Transition duration={0}>
            <img src={Exploded} alt="expoded" />
          </Transition>
          <Transition duration={0}>
            {currentStep === 3 && (
              <Grow>
                <img src={Trimmed} alt="trimmed" />
              </Grow>
            )}
          </Transition>
          <Transition duration={0.3}>
            <SkinSVG stepper={0} />
          </Transition>
          <Transition  duration={0.3}>
          <SkinSVG stepper={1} />
          </Transition>
          <Transition  duration={0.3}>
            <SkinSVG stepper={2} />
          </Transition>
          <Transition  duration={0.3}>
            <SkinSVG stepper={3} />
          </Transition >
          <Transition duration={0.3}>
          {currentStep === 8 && <Carbon />}
          </Transition>
        </Steps>
      </div>
    </div>
  );
}

export default InformationDisplay;
