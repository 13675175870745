import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { MaterialContext } from "../context/MaterialContext";
import { closeIcon } from "./Icons";

function MobileSwatch({ slug, setPop }) {
  const { materialData, changeSelected } = useContext(MaterialContext);

  const materialItem = materialData.find((item) => item.slug === slug);
  const handleRemoveSelected = (e, id) => {
    e.stopPropagation()
    changeSelected(id);
  };

  return (
    <>
      {materialItem && (
        <div className="mobile-swatch">
                        <div
              className="mobile-pop-close swatch-close"
              onClick={(e) => handleRemoveSelected(e, materialItem.id)}
            >
              {closeIcon}
            </div>
          <Link to={`./${slug}`} onClick={() => setPop(false)} className="mobile-swatch-image">
            <div className="vendor">
              <h6>{materialItem.vendor}</h6>
            </div>

            <img src={materialItem.gallery_swatch[0]} alt={slug} />
          </Link>
          <div className="mobile-swatch-info">
            <h4>{materialItem.title}</h4>
          </div>
        </div>
      )}
    </>
  );
}

export default MobileSwatch;
